<template>
  <CModal
    :title="$t('edit_order')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <h4 class="mb-3">{{ $t('order_information') }}</h4>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('order_no')"
          :placeholder="$t('order_no')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('status')"
          :placeholder="$t('status')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="statusOptions.find(option => option.value === orderData.status).label"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('booking_name')"
          :placeholder="$t('booking_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.order_info.booking_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('booking_phone')"
          :placeholder="$t('booking_phone')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.order_info.booking_phone"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('booking_note') }}
          </label>
          <div class="col-form-label col-sm-9" v-html="orderData.order_info.booking_note">
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-12">
            {{ $t('agent_note') }}
          </label>
          <div class="col-sm-12">
            <textarea-autosize
              :placeholder="$t('agent_note')"
              :class="['form-control', valid_formData_valid_formData_introduction ? 'is-valid' : 'is-invalid']"
              v-model="formData.agent_note"
              :min-height="100"
              :max-height="350"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditOrderAgentNoteModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      statusOptions: [
        { value: 'pending', label: this.$t('pending') },
        { value: 'completed', label: this.$t('completed') },
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'refunding', label: this.$t('refunding') },
        { value: 'refunded', label: this.$t('refunded') },
        { value: 'cancelled', label: this.$t('cancelled') },
        { value: 'expired', label: this.$t('expired') },
        { value: 'closed', label: this.$t('closed') },
      ],
      formData: {},
      orderData: {}
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showOrder()
      }
    }
  },
  computed: {
    valid_submit () {
      return true
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showOrder () {
      const loader = this.$loading.show()
      this.$store.dispatch('showOrder', { id: this.modal.data.id }).then(res => {
        this.formData = { id: res[0].id, status: res[0].status, notify: 0, agent_note: res[0].order_info?.agent_note || '' }
        this.orderData = res[0]
        this.orderData.order_info.booking_note = this.orderData.order_info.booking_note ? this.orderData.order_info.booking_note.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>') : ''
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateOrder', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
